import React, { useCallback } from "react";
import styled from "styled-components";

import { device } from "../../../../../../utils";
import { Heading1, Button, Paragraph } from "../../../../atoms";
import * as analytics from "../../../../../../analytics";
import SwitzerlandImageDesktop from "./switzerland-desktop.webp";
import SwitzerlandImageMobile from "./switzerland-mobile.webp";
import SwitzerlandLogo from "./switzerland-logo.svg";

const Switzerland = styled("div")`
  display: flex;
  height: 589px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 37px;
  margin-top: 37px;
  border-radius: 7px;
  overflow: hidden;
  flex-direction: column;
  width: 344px;
  @media ${device.tablet} {
    flex-direction: row;
    width: 745px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.13);
  }
  @media ${device.laptop} {
    width: 920px;
    margin-bottom: 52px;
  }
  @media ${device.desktop} {
    margin-top: 0;
  }
`;

const LogoSection = styled("div")`
  width: 100%;
  height: 260px;
  border-radius: 8px 8px 0px 0px;
  align-content: center;
  align-items: center;
  background: url(${SwitzerlandImageMobile}) lightgray no-repeat center center;
  background-size: cover;
  @media ${device.tablet} {
    background-size: contain;
    border-radius: 0;
    background: url(${SwitzerlandImageDesktop}) lightgray -4.507px -59.269px /
      101.573% 112.661% no-repeat;
    max-width: 445px;
    min-width: 50%;
    flex-shrink: 0;
    align-self: stretch;
    width: unset;
    height: unset;
  }
`;

const Logo = styled("div")`
  background-image: url(${SwitzerlandLogo});
  background-size: cover;
  margin: auto;
  width: 213px;
  height: 213px;
  @media ${device.tablet} {
    width: 240px;
    height: 240px;
  }
  @media ${device.laptop} {
    width: 333px;
    height: 330px;
  }
`;

const Content = styled("div")`
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background.white};
  @media ${device.tablet} {
    padding: 80px 16px;
  }
  @media ${device.laptop} {
    padding: 142px 48px;
    max-width: 475px;
  }
`;

const InterestSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${device.tablet} {
    gap: 20px;
  }
`;

const H1 = styled(Heading1)`
  font-size: 24px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 30px;
  }
`;

const InfoSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 0px 4px;
  @media ${device.laptop} {
    padding: 0px 8px;
  }
`;

const Info = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 13px;
`;

const InterestButton = styled(Button)`
  width: 100%;
  font-weight: bold;
  align-self: center;

  &:active {
    ${({ theme, disabled }) =>
      !disabled && `background-color: ${theme.colours.ruubyLightGrey}`};
  }

  @media ${device.tablet} {
    max-width: 335px;
  }
`;

const AnnounceParagraph = styled(Paragraph)`
  text-align: center;
`;

const Link = styled("a")`
  color: ${({ theme }) => theme.colours.text.main};
  text-decoration: underlined;
  font-weight: normal;
`;

export const SwitzerlandPanel = (): JSX.Element => {
  const openInNewTab = useCallback((): void => {
    analytics.track({ name: analytics.AnalyticsEvents.SWITZERLAND_INTEREST });
    const newWindow = window.open(
      "https://share.hsforms.com/1z_d4avrcRdmNlXkPQaBvcQ3scj5",
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  }, []);

  return (
    <Switzerland>
      <LogoSection>
        <Logo />
      </LogoSection>
      <Content>
        <InterestSection>
          <H1>Ruuby is coming to Switzerland!</H1>
          <InfoSection>
            <Info>
              <AnnounceParagraph>
                We are thrilled to announce the upcoming launch of Ruuby
                Switzerland, debuting in Zurich in October 2024. Be the first to
                hear about our exclusive offers and updates by registering your
                interest below.
              </AnnounceParagraph>
            </Info>
            <Info>
              <InterestButton size="small" onClick={openInNewTab}>
                Register your interest
              </InterestButton>
            </Info>
            <Info>
              <AnnounceParagraph>
                If you are a beauty and wellness professional looking to join
                the Ruuby Switzerland platform, please email 
                <Link href="mailto:recruitment@ruuby.com">
                  recruitment@ruuby.com
                </Link>
              </AnnounceParagraph>
            </Info>
          </InfoSection>
        </InterestSection>
      </Content>
    </Switzerland>
  );
};
